<template>
  <div class="container" :style="setBgColor()">
    <div class="logo-item" @click="backHome">
      <img :src="logoUrl" class="logo-image" alt="">
      <div style="font-size: 32px; color: #4EA6E5; font-weight: 600">{{ shop_name }}</div>
    </div>
    <div class="right-panel">
      <el-menu :default-active="activeIndex" :text-color="textColor" :active-text-color="activeTextColor" :background-color="backgroundColor" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/index">{{ $t('headers.index') }}</el-menu-item>
        <el-menu-item index="/message">{{ $t('headers.message') }}</el-menu-item>
        <el-menu-item index="/logistics-query">{{ $t('headers.query') }}</el-menu-item>
      </el-menu>
      <lang-select class="right-menu-item hover-effect" />
      <el-dropdown class="currency-dropdown" v-if="currency.length">
        <span class="currency-trigger" :style="setColor()">
          <i class="el-icon-money"></i>
          <span class="currency-value">{{ formatCurrency(showCurrency) }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in currency" 
                           :key="item.Id"
                           @click.native="selectCurrency(item)">
            <span class="currency-item" 
                  :class="{ 'active': item.Id === showCurrency.Id }">
              {{ formatCurrency(item) }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="user-item" :style="setColor()">
        <div class="u-start-flex" v-if="isAuthenticated">
          <el-dropdown trigger="click" class="international">
            <personal class="mr8"></personal>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="$router.push({path: '/member'})" style="padding: 0 2rem">{{ $t('headers.member') }}</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="$router.push({path: '/member/account'})" style="padding: 0 2rem">{{ $t('layout.member.accountSettings') }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

<!--          <span @click="$router.push({path: '/member'})" class="pointer">{{ userInfo.Name }}</span>-->
<!--          <span style="margin: 0 1rem">|</span>-->
<!--          <span @click="$router.push({path: '/member'})" class="pointer">{{ $t('headers.member') }}</span>-->
<!--          <span style="margin: 0 1rem">|</span>-->
          <logout></logout>
<!--          <span class="pointer" @click="logout">{{ $t('headers.logout') }}</span>-->
        </div>
        <div class="u-start-flex" v-else>
          <div class="pointer" @click="$router.push({path: '/login'})">{{ $t('headers.login') }}</div>
          <div style="margin: 0 1rem">|</div>
          <div class="pointer" @click="$router.push({path: '/register'})">{{ $t('headers.register') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LangSelect from "@/components/LangSelect/index.vue";
import Logout from "@/components/icss/logout.vue";
import Personal from "@/components/icss/personal.vue";
export default {
  data() {
    return {
      activeIndex: "",
      selectedCurrency: null
    }
  },
  components: {Personal, LangSelect, Logout },
  computed: {
    textColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return '#303133'
      }
    },
    activeTextColor() {
      if (this.internal) {
        return '#FFFFFF'
      } else {
        return ''
      }
    },
    logoPath() {
      if (this.internal) {
        return 'bg_logo.png'
      } else {
        return 'logo.png'
      }
    },
    backgroundColor() {
      if (this.internal) {
        return '#194D8F'
      } else {
        return ''
      }
    },
    ...mapGetters(['path', 'internal', 'isAuthenticated', 'userInfo', 'logoUrl', 'shop_name', 'currency']),
    showCurrency() {
      if (this.selectedCurrency) {
        return this.selectedCurrency;
      }
      return this.currency.length ? this.currency[0] : {};
    }
  },
  methods: {
    handleSelect() {},
    backHome() {
      this.$router.push({ path: '/index' });
    },
    logout() {
      this.$store.dispatch('user/logout')
    },
    setBgColor() {
      return {
        backgroundColor: this.internal ? '#194D8F' : '#fff',
        height: this.internal ? '8rem' : '10rem',
        padding: this.internal ? '0 1%' : '0 10%'
      }
    },
    setColor() {
      return {
        color: this.internal ? '#ffffff' : '#303133'
      }
    },
    formatCurrency(currency) {
      if (!currency) return '';
      return `1 RMB = ${currency.ExchangeRate} ${currency.Code}`;
    },
    selectCurrency(currency) {
      this.selectedCurrency = currency;
    }
  },
  mounted() {
    this.activeIndex = this.path;
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
    }
  }
}

</script>

<style lang="scss" scoped>
.el-menu-item:hover {
  background-color: transparent !important;
}
::v-deep .el-dropdown-menu__item, .el-menu-item {
  font-size: 1.8rem;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal>.el-menu-item {
  margin: 0 3rem !important;
}
::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.25rem solid #409EFF;
  color: #303133;
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }
}
.container {
  padding: 0 10%;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .logo-item {
    font-family: cursive;
    cursor: pointer;
    display: flex;
    align-items: center;
    .logo-image {
      height: 4rem;
      object-fit: cover;
      margin-right: 1rem;
    }
  }
  .right-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .user-item {
      margin-left: 2.5rem;
      font-size: 1.8rem;
    }
  }
}
.currency-dropdown {
  margin-left: 20px;
  
  .currency-trigger {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    cursor: pointer;
    padding: 0 12px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(64, 158, 255, 0.1);
    }
    
    .el-icon-money {
      margin-right: 6px;
      font-size: 1.8rem;
    }
    
    .currency-value {
      margin-right: 4px;
    }
    
    .el-icon--right {
      font-size: 12px;
      transition: transform 0.3s ease;
    }
  }
}

::v-deep .el-dropdown-menu {
  padding: 8px 0;
  
  .el-dropdown-menu__item {
    padding: 8px 16px;
    line-height: 1.5;
    
    &:hover {
      background-color: #f5f7fa;
    }
    
    .currency-item {
      display: block;
      font-size: 1.4rem;
      
      &.active {
        color: #409EFF;
        font-weight: 500;
      }
    }
  }
}

::v-deep .el-dropdown.is-active .el-icon-arrow-down {
  transform: rotate(180deg);
}
</style>
